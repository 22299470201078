<template>
  <router-view />
</template>
<script>
import { onMounted } from "vue";
import { _axios } from "@/plugins/ktandgAxios";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
//axios 전역으로 선언
export default {
  name: "App",
  setup() {
    const store = useStore();
    const t = useI18n();

    // URL 통해 data 가져오기
    const getAuthDiag = async () => {
      let url = "";
      if (window.location.hostname.includes("stage")) {
        // stage 시에 사용
        url = window.location.hostname.split(".")[0].split("-")[0];
      } else if (window.location.hostname.includes("carrotleaders")) {
        // stage 가 아니라 master 일 때 사용
        url = window.location.hostname.split(".")[0];
      } else {
        // localhost 또는 ip 에서 사용
        url = "hwaneetest1";
      }
      const result = await _axios.get(`/app/auth/diag/${url}`);

      const { success, data, message } = result.data;
      if (success) {
        // diag 관련된 회사 명은 store 에 관리
        // app 에서 하는 이유는 어떤 URL 을 접속하던, url에 따라서 사용하도록 처리
        await store.dispatch("DiagInfoInit", data);
        // 언어변경
        t.locale.value = data.language;

        // t.locale.value = "en";
      } else {
        alert(message);
      }
    };

    onMounted(() => {
      getAuthDiag();
      window.addEventListener("DOMContentLoaded", function () {
        if (screen.width < 450) {
          const viewport = document.getElementById("viewport");
          viewport.setAttribute(
            "content",
            "width=450, maximum-scale=1, user-scalable=no"
          );
        }
      });

      window.addEventListener("resize", function () {
        const viewport = document.getElementById("viewport");

        if (screen.width < 450) {
          viewport.setAttribute(
            "content",
            "width=450, maximum-scale=1, user-scalable=no"
          );
        } else {
          viewport.setAttribute(
            "content",
            "width=device-width, initial-scale=1.0, maximum-scale=1, user-scalable=no"
          );
        }
      });
    });
  },
};
</script>
<style lang="scss">
@import "@/assets/styles/reset.scss";
</style>
