import { createStore } from "vuex";
import persistedStateVuex from "persisted-state-vuex";
import { _axios } from "@/plugins/ktandgAxios";

persistedStateVuex.config({
  storageKey: "leadershipUserVuex",
});
export default createStore({
  state: {
    accessToken: "",
    diagIdx: "",
    diagCode: "",
    diag_subject_name: "",
    diagInfo: {
      idx: -1, // diag idx
      company_name: "", // 고객사 명
      company_logo_url: "", // 고객사 로고 url
      company_logo_filename: "", //고객사 로고 url 파일명
      company_sub_domain: "", // 진단 도메인
      group_name: "", // 진단명 (내부용)
      group_name_show: "", // 진단명 (노출용)
      group_desc: "", // 메인설명 문구 (노출용)
      group_start_guide_url: "", // start guide 이미지
      group_start_guide_filename: "", // start guide 이미지 파일명
      start_date: "", // 진단기간 시작일
      end_date: "", // 진단기간 종료일
      idx_leadership_question_main: -1, // 선택된 문항 수
      contact_manager: "", // 발송안내 담당자 명
      contact_email: "", // 발송안내 메일 발송 계정
      contact_title: "", // 발송안내 메일 제목
      contact_enquiry: "", // 진단 문의
      contact_termination_notice: "", // 진단 조료 안내
      submit_btn_name: "", // 노출용 버튼 명
    },
  },
  getters: {
    getTokenInfo(state) {
      return state.accessToken;
    },
    getDiagIdxInfo(state) {
      return state.diagIdx;
    },
    getDiagCodeInfo(state) {
      return state.diagCode;
    },
    getDiag_Subject_NameInfo(state) {
      return state.diag_subject_name;
    },
  },
  mutations: {
    setDiagInfo(state, payload) {
      state.diagInfo = payload;
    },
    setAccessToken(state, payload) {
      state.accessToken = payload;
    },
    setDiagIdx(state, payload) {
      state.diagIdx = payload;
    },
    setDiagCode(state, payload) {
      state.diagCode = payload;
    },
    setDiag_Subject_Name(state, payload) {
      state.diag_subject_name = payload;
    },
  },
  actions: {
    async DiagInfoInit({ commit }, info) {
      commit("setDiagInfo", info);
    },
    async tokenInit({ commit }, token) {
      commit("setAccessToken", token);
    },
    async idxInit({ commit }, idx) {
      commit("setDiagIdx", idx);
    },
    async codeInit({ commit }, code) {
      commit("setDiagCode", code);
    },
    async nameInit({ commit }, diag_subject_name) {
      commit("setDiag_Subject_Name", diag_subject_name);
    },
  },
  plugins: [persistedStateVuex.init],
});
