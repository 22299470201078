import { createApp } from "vue";
import App from "@/App.vue";
import { createI18n } from "vue-i18n";
//vuex
import store from "@/store";

//router
import router from "./router";
import language from "@/assets/i18n/index";

const messages = language;

const i18n = createI18n({
  legacy: false,
  locale: "ko",
  fallbackLocale: "ja", // set fallback locale
  messages,
});

const app = createApp(App).use(router);
app.use(i18n);
app.use(store);
app.mount("#app");
