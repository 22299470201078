import kor from "./kor.json";
import idn from "./idn.json";
import kaz from "./kaz.json";
import twn from "./twn.json";
import chn from "./chn.json";
import rus from "./rus.json";
import mng from "./mng.json";
import tur from "./tur.json";

const json = {
  ko: kor,
  id: idn,
  kk: kaz,
  "zh-TW": twn,
  cn: chn,
  ru: rus,
  mn: mng,
  tr: tur,
};

export default json;
