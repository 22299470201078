import axios from "axios";

axios.defaults.timeout = 5000; // ms
axios.defaults.retry = 4; // count
axios.defaults.retryDelay = 1000; // ms
let targetUrl = "";
if (location.origin.includes("local") || location.origin.includes("stage")) {
  targetUrl = `https://stage-api.carrotleaders.com`;
} else {
  targetUrl = `https://api.carrotleaders.com`;
}
const _axios = axios.create({
  baseURL: targetUrl,
  // baseURL: "http://192.168.100.74:8500/",
  timeout: 5000,
});

/* 인터셉터 request */
_axios.interceptors.request.use(
  (config) => {
    config.headers.post["Content-Type"] = "application/json";
    config.headers.put["Content-Type"] = "application/json";
    config.withCredentials = false;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/* 인터셉터 response */
// Add a response interceptor
_axios.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  (error) => {
    var config = error.config;
    console.error("Network Error, Retry", config);
    if (error.response.data.path == "/login/msg") {
      alert("세션이 끊어졌습니다. 로그인 페이지로 이동합니다.");
      window.location.href = "/login";
    } else {
      // If config does not exist or the retry option is not set, reject
      if (!config || !config.retry) {
        console.log("config.retry Not Exist");
        return Promise.reject(error);
      }

      // Set the variable for keeping track of the retry count
      config.__retryCount = config.__retryCount || 0;

      // Check if we've maxed out the total number of retries
      if (config.__retryCount >= config.retry) {
        // Reject with the error
        console.log("Retry Count Overflow");
        return Promise.reject(error);
      }

      // Increase the retry count
      config.__retryCount += 1;

      // Create new promise to handle exponential backoff
      var backoff = new Promise(function (resolve) {
        console.log("Create New Promise");
        setTimeout(function () {
          resolve();
        }, config.retryDelay);
      });

      // Return the promise in which recalls axios to retry the request
      return backoff.then(function () {
        console.log("Recalls Axios to Retry");
        return _axios(config);
      });
    }
  }
);

export { _axios };
